<template>
  <Grid
    class="exhibitions"
    col="12"
    :row-gap="$mq.isMobile ? 'xl' : 'xxl'"
  >
    <Archive
      :data="{
        acf_fc_layout: 'archive',
        archive: 'fairs',
        archive_type: 'archive',
        layout: [
          {
            layout_size: '12',
            layout_type: 'grid',
            media_query: 'default',
          },
          {
            layout_size: '4',
            layout_type: 'grid',
            media_query: 'm',
          },
        ],
        posts_per_page: '100',
        title: 'Current',
        calendar: 'current',
        thumb: 'calendar',
      }"
    />

    <Archive
      :data="{
        acf_fc_layout: 'archive',
        archive: 'fairs',
        archive_type: 'archive',
        layout: [
          {
            layout_size: '12',
            layout_type: 'grid',
            media_query: 'default',
          },
          {
            layout_size: '4',
            layout_type: 'grid',
            media_query: 'm',
          },
        ],
        posts_per_page: '100',
        title: 'Upcoming',
        calendar: 'upcoming',
        thumb: 'calendar',
      }"
    />

    <Archive
      :data="{
        acf_fc_layout: 'archive',
        archive: 'fairs',
        archive_type: 'archive',
        layout: [
          {
            layout_size: '12',
            layout_type: 'grid',
            media_query: 'default',
          },
          {
            layout_size: '4',
            layout_type: 'grid',
            media_query: 'm',
          },
        ],
        posts_per_page: '100',
        title: 'Past',
        calendar: 'past',
        thumb: 'calendar',
      }"
    />
  </Grid>
</template>

<script>
import Archive from '@/components/blocks/archive';

export default {
  name: 'Fairs',
  components: {
    Archive,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.exhibitions {
  ::v-deep {
    .title-wrapper {
      /* top: 88px;

      .has_nav__up & {
        top: 10px;
      } */

      @include mq(m) {
        top: 4.75vw;
        position: sticky;
      }
      text-transform: uppercase;
      z-index: 2;
    }
  }
}
</style>
