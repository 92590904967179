<template>
  <div class="accordion-wrapper">
    <AccordionWrapper
      v-for="(item, index) in data.items"
      :key="index"
    >
      <AccordionItem>
        <template slot="accordion-trigger">
          <Cta :data="{ title: item.title_trigger, fn: () => {}}" />
        </template>
        <template slot="accordion-content">
          <Content :data="{ title: item.title, rich_text: item.rich_text}" />
        </template>
      </AccordionItem>
    </AccordionWrapper>
  </div>
</template>

<script>
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import AccordionItem from '@/components/ui/accordion-item';

import Content from '@/components/blocks/content';
import Cta from '@/components/typo/cta';

export default {
  name: 'Accordion',
  components: {
    AccordionWrapper,
    AccordionItem,
    Content,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">

.accordion__trigger {
  cursor: pointer;
  &--active {
    .form-group__header,
    .form-wrapper__header,
    .chevron {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .form-group__header,
  .form-wrapper__header,
  .chevron {
    position: relative;
    padding-right: var(--spacer-m);
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: $unit*1.5;
      height: var(--spacer-m);
      background: url('~@/assets/svg/icons/chevron.svg');
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.3s ease;
    }
  }
}

</style>
