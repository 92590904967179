<template>
  <div class="page__404">
    <inline-svg
      class="logo__404"
      :src="require(`@/assets/svg/lupetto_404.svg`)"
    />
    <div class="page__404--title">
      404 Page not found
    </div>
    <router-link
      to="/"
      class="page__404--text"
    >
      Back to homepage
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.logo__404 {
  width: 75px;
  margin-bottom: 23px;
  @include mq(m) {
    margin-bottom: 33px;
  }
}
.page__404 {
    // background: red;

  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &--title {
    @extend %typo--ms;
    margin-bottom: 8px;
    @include mq(m) {
      margin-bottom: 12px;
    }
  }
  &--text {
    @extend %typo--xs;
  }
}
</style>
