<template>
  <Spacer
    v-show="items.length > 0"
    x="s"
  >
    <Spacer
      v-if="data.title"
      bottom="s"
      class="title-wrapper"
    >
      <Title
        v-if="data.archive !== 'book'"
        :data="{ value: data.title, size: data.calendar ? 'l' : 'ms' }"
      />

      <inline-svg
        v-if="data.archive === 'book'"
        :src="require(`@/assets/svg/books.svg`)"
      />
    </Spacer>
    <Grid
      v-if="items.length > 0"
      :col="gridCol"
      :template="gridTemplate"
      :column-gap="data.calendar ? 'xl' : undefined"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumb(item.type)"
          :data="{ ...item, index }"
        />
      </div>
    </Grid>
    <div v-else>
      <Skeleton
        :items="skeletonItems"
        :cols="gridCol"
        :thumb-component="type"
      />
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Cta
      v-if="data.cta"
      :data="data.cta"
    />
  </Spacer>
</template>

<script>
import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import ThumbBook from '@/components/thumb/book';
import ThumbCalendar from '@/components/thumb/calendar';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Skeleton from '@/components/ui/skeleton';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('book', ThumbBook);
thumbs.set('calendar', ThumbCalendar);

export default {
  name: 'Archive',
  components: {
    Title,
    Cta,
    Skeleton,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
              layout_size === 'auto'
                ? (12 / this.items.length).toString()
                : layout_size,
          };
        });
      } else {
        cols = {
          default: 12,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
  },
  methods: {
    thumb(key) {
      if (this.data.thumb) return thumbs.get(this.data.thumb);
      return key && thumbs.get(key) ? thumbs.get(key) : thumbs.get('default');
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  visibility: hidden;
}
.title {
  // margin-bottom: 14px;
  // text-transform: uppercase;
}
</style>
