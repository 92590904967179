import { render, staticRenderFns } from "./coming-soon.vue?vue&type=template&id=0f5a8343&scoped=true"
import script from "./coming-soon.vue?vue&type=script&lang=js"
export * from "./coming-soon.vue?vue&type=script&lang=js"
import style0 from "./coming-soon.vue?vue&type=style&index=0&id=0f5a8343&prod&lang=scss&scoped=true"
import style1 from "./coming-soon.vue?vue&type=style&index=1&id=0f5a8343&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5a8343",
  null
  
)

export default component.exports