var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('Flex',{staticClass:"artists__flex",attrs:{"gap":"","justify":"space-around"}},_vm._l((_vm.navigation),function(item){return _c('div',{key:item.id,class:[...item.css]},[_c('Cta',{staticClass:"artist__cta",attrs:{"data":{
          url: item.url,
          title: item.content,
          target: item.target,
        }},nativeOn:{"mouseenter":function($event){return _vm.setShowCover(item.id)},"mouseleave":function($event){return _vm.setShowCover(false)}}}),_c('transition',{attrs:{"name":"fade"}},[_c('Figure',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCover === item.id),expression:"showCover === item.id"}],staticClass:"artist__cover",attrs:{"data":{
            default: item.acf.cover_artist,
          }}})],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }