<template>
  <div class="thumb">
    <router-link
      :to="data.link"
      class="thumb__link"
    >
      <div class="thumb__figure">
        <Figure
          v-if="data.acf.thumb_mobile"
          class="mobile"
          :data="{ ...data.acf.thumb_mobile, disableCaption: true }"
        />

        <Figure
          v-if="data.gds_featured_image"
          class="desktop"
          :data="{ ...data.gds_featured_image, disableCaption: true }"
        />
      </div>
      <div class="thumb__info">
        <div class="thumb__info--box">
          <!-- {{ data.acf }} -->
          <div class="thumb__info--box--inside">
            <div>
              <p
                v-if="artists"
                :class="['thumb__artist', $mq.isMobile ? 'typo--s' : 'typo--m']"
                v-html="artists"
              />

              <Title
                :data="{
                  value: data.taxonomy ? data.name : data.title.rendered,
                  size: $mq.isMobile ? 's' : 'm',
                }"
              />
            </div>
            <p :class="['thumb__dates', 'typo--s']">
              {{ data.acf.thumb_dates }}
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

export default {
  name: 'ThumbCalendar',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    artists() {
      if (this.data.acf.artists) {
        const array = this.data.acf.artists.map((el) => (el.artist_page ? el.artist.post_title : el.artist_title));
        return array.join(', ');
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  @include mq($and: $hover) {
    &:hover {
      a {
        color: black;
      }
    }
  }

  &__figure {
    position: relative;
    height: 0;
    padding-bottom: 50%;

    @include mq($until: m) {
      width: 100px;
      flex: 0 0 120px;
      padding-bottom: 0;
      height: 100px;
    }

    * {
      position: absolute;
      height: 100%;
    }
  }

  &__link {
    display: block;
    width: fit-content;
    width: 100%;
    height: 100%;
    position: relative;

    @include mq(m) {
      height: fit-content;
    }

    @include mq($until: m) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__info {
    text-transform: uppercase;

    .thumb__dates {
      margin-top: 10px;
    }
    .title {
      font-style: italic;
    }
    &--box {
      padding: 20px 0px 0px;

      @include mq($until: m) {
        padding: 0 6px;
      }

      &--inside {
        @include mq($until: m) {
          min-height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }

      @include mq($and: $hover) {
        &:hover {
          * {
            color: red;
          }
        }
      }
    }
  }
  &__artist {
    text-transform: uppercase;
  }
}
.picture {
  width: 100%;
  @include mq(s) {
    width: 100%;
  }
}

.desktop {
  display: none;
  @include mq(m) {
    display: block;
  }
}

.mobile {
  @include mq(m) {
    display: none;
  }
}
</style>
