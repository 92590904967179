<template>
  <div
    class="container"
    :style="{
      '--width': `${scale * data.width}px`,
      '--height': `${scale * data.height}px`,
      '--depth': `${scale * data.depth}px`,
      '--speed': `${data.index + 10}s`,
      '--direction': data.index % 2 === 0 ? 'normal' : 'reverse',
    }"
  >
    <div class="boockup">
      <div
        class="book-container"
        :style="{ backgroundColor: data.background }"
      >
        <div
          class="book-front"
          :style="{ backgroundImage: `url(${data.cover.url})` }"
        />
        <div
          class="book-side-left"
          :style="{ backgroundImage: `url(${data.spine.url})` }"
        />
        <div class="book-side-right" />
        <div class="book-top" />
        <div class="book-bottom" />
        <div
          class="book-back"
          :style="{ backgroundImage: `url(${data.back.url})` }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Book',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scale: 1.2,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  perspective: 1200px;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.boockup {
  transform-style: preserve-3d;
  transform: rotateX(-2deg) rotateY(-107deg) rotateZ(0deg);
}
.book-container {
  transform-style: preserve-3d;
  animation: 10s rotatingAnimationY linear infinite;
  animation-duration: var(--speed);
  animation-direction: var(--direction);

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    background-position: center center;
    backface-visibility: visible;
  }
}

.book-top, .book-bottom, .book-side-right {
  background-size: auto auto;
  background-repeat: repeat;
  background-position: top left;
}

.book-front {
  width: var(--width);
  height: var(--height);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(0,0,0) rotateY(0deg);
}

.book-side-left {
  width: var(--depth);
  height: var(--height);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(calc(var(--depth) * -0.5), 0, calc(var(--depth) * -0.5)) rotateY(-90deg);
}

.book-side-right {
  background-image: url('~@/assets/img/book-side.jpg');
  width: var(--depth);
  height: var(--height);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(calc(var(--width) - (var(--depth) * 0.5)), 0px,calc(var(--depth) * -0.5)) rotateY(90deg);
}
.book-top {
  background-image: url('~@/assets/img/book-top.jpg');
  background-position: bottom right;
  width: var(--width);
  height: var(--depth);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(0px, calc(var(--depth) * -0.5), calc(var(--depth) * -0.5)) rotateX(90deg);
}
.book-bottom {
  background-image: url('~@/assets/img/book-top.jpg');
  width: var(--width);
  height: var(--depth);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(0px, calc(var(--height) - (var(--depth) * 0.5)), calc(var(--depth) * -0.5)) rotateX(-90deg);
}
.book-back {
  width: var(--width);
  height: var(--height);
  transform: translate3d(calc(var(--width) * -0.5), calc(var(--height) * -0.5), calc(var(--depth) * 0.5)) translate3d(0,0, calc(var(--depth) * -1)) rotateY(180deg);
}

@keyframes rotatingAnimationY {
  0%{
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
</style>
