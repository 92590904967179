<template>
  <Grid
    col="12"
  >
    <router-link :to="data.link || data.permalink">
      <Media
        v-if="data.gds_featured_image"
        :data="{
          type: 'image',
          image: data.gds_featured_image,
          ratio: false,
          object_fit: 'cover',
        }"
        class="media"
      />
      <Title
        :data="{
          value: data.taxonomy || data.name ? data.name : data.title.rendered,
        }"
      />
    </router-link>
    <div>
      <Price :product="data" />
      <Availability :product="data" />
    </div>
    <Flex justify="center">
      <Cta
        v-if="!hasVariations"
        theme="button"
        :data="{
          title: $labels.add_to_cart,
          url: false,
          fn: addToCart,
        }"
        :disabled="!addableToCart"
      />

      <Cta
        v-else
        theme="button"
        :data="{
          title: $labels.select_option,
          url: data.link || data.permalink,
        }"
      />
    </Flex>
  </Grid>
</template>

<script>
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Price from '@/components/wc/price.vue';
import Availability from '@/components/wc/availability.vue';

export default {
  name: 'Thumb',
  components: {
    Media,
    Title,
    Cta,
    Price,
    Availability,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'link',
    },
  },
  computed: {
    isOos() {
      return isOos(this.data);
    },
    hasVariations() {
      return this.data.type.startsWith('variable');
    },
    addableToCart() {
      if (!addableToCart(this.data) || isOos(this.data)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    addToCart() {
      this.$store.dispatch('addToCart', Object.assign(this.data, {
        id: String(this.data.id),
        quantity: 1,
        quantityDelta: 1,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.media {
  @include aspect-ratio(4, 3);
}
</style>
