<template>
  <header v-show="!$route.meta.hideHeader">
    <div>
      <!-- <router-link to="/">
        {{ $store.state.wp.site.name }}
      </router-link> -->

      <div class="logo-wrapper logo__desktop">
        <router-link to="/">
          <inline-svg
            class="logo logo__fixed"
            :src="require(`@/assets/svg/logo_fix.svg`)"
            :style="!$mq.isMobile ? { transform: `translate3d(0, ${scroll}px, 0)` } : null"
          />
          <inline-svg
            class="logo logo__absolute"
            :src="require(`@/assets/svg/logo_abs.svg`)"
          />
        </router-link>
      </div>

      <div
        class="logo-wrapper logo__mobile"
        :class="{ nav__up: navUp }"
      >
        <router-link to="/">
          <inline-svg
            class="logo"
            :src="require(`@/assets/svg/logo_mobile.svg`)"
          />
        </router-link>
      </div>

      <div
        class="menu__mobile"
        :class="{ nav__up: navUp }"
        @click="openMenuMobile"
      >
        Menu
      </div>

      <Menu />
      <!-- <WcMenu /> -->
    </div>
  </header>
</template>

<script>
import gsap, { ScrollToPlugin } from 'gsap/all';

import Menu from '@/components/ui/menu';
// import WcMenu from '@/components/wc/wc-menu';
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Header',
  components: {
    Menu,
    // WcMenu,
  },
  data() {
    return {
      navUp: false,
      menuMobileOpen: false,
      scroll: 0,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        document.querySelector('.nav').classList.remove('nav__mobile--open');
        document.querySelector('main').classList.remove('main__mobile');
        document.querySelector('.logo__mobile').classList.remove('main__mobile');
        document.querySelector('.menu__mobile').classList.remove('main__mobile');
        this.menuMobileOpen = false;
        document.querySelector('.menu__mobile').innerHTML = 'Menu';
      }
    },
  },
  mounted() {
    // document.addEventListener('scroll', this.moveHeader);
    this.$aion.add(this.moveHeader, 'header');
  },

  methods: {
    openMenuMobile() {
      document.querySelector('.nav').classList.toggle('nav__mobile--open');
      document.querySelector('main').classList.toggle('main__mobile');
      document.querySelector('.logo__mobile').classList.toggle('main__mobile');
      document.querySelector('.menu__mobile').classList.toggle('main__mobile');
      if (this.$parent.toggleArtist) {
        this.$parent.openArtists();
      }
      this.menuMobileOpen = !this.menuMobileOpen;
      if (this.menuMobileOpen) {
        document.querySelector('.menu__mobile').innerHTML = 'Close';
      } else {
        document.querySelector('.menu__mobile').innerHTML = 'Menu';
      }
    },
    moveHeader() {
      const diff = this.$parent.toggleArtist ? this.$parent.artistHeight : 0;
      if (this.prevScrollpos !== window.pageYOffset && this.menuMobileOpen) {
        this.openMenuMobile();
      }

      if (!this.$parent.toggleArtist || (this.$parent.toggleArtist && window.pageYOffset >= this.$parent.artistHeight)) {
        if (this.$parent.toggleArtist) {
          this.$parent.closeArtists(true);
          window.scroll(0, 0);
        }
        this.scroll = window.pageYOffset - diff;
      } else {
        this.scroll = 0;
      }
      const currentPosition = window.pageYOffset;
      if (this.prevScrollpos > currentPosition) {
        console.log(this.prevScrollpos);
        console.log(currentPosition);
        this.navUp = false;
        document.body.classList.remove('has_nav__up');
      } else if (currentPosition > 0 && this.menuMobileOpen === false && currentPosition > this.prevScrollpos) {
        this.navUp = true;
        document.body.classList.add('has_nav__up');
      }
      this.prevScrollpos = currentPosition;
    },

  },
};
</script>

<style lang="scss" scoped>

header {
  position: sticky;
  position: relative;
  top: 0;
  padding: 10px;
  height: 78px;
  z-index: 100000;
  @include mq(m) {
    height: 8vw;
  }
}
.logo {
  &__fixed {
    position: absolute;
    z-index: 1000;
    width: calc(100% - 20px);
  }
  &__absolute {
    position: absolute;
    width: calc(100% - 20px);
    // transform: translateY(1px);
    transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);

    .artists-opened & {
      transform: translateY(calc(-1 * var(--artist-menu)));
    }
  }
}
.logo__desktop {
  display: none;
  @include mq(m) {
    display: block;
  }
}

.menu__mobile{
  z-index: 100000;
    position: fixed;
    cursor: pointer;
    top: 10px;
    right: 10px;
    transition: all .2s ease-in-out;
    @include mq(m){
      display: none;
    }
}

.logo__mobile {
  height: 60px;
  width: 242px;
  position: fixed;
  z-index: 100;
  transition: all .2s ease-in-out;
  @include mq(m) {
    display: none;
  }
}

.nav__up {
    transform: translateY(-75px);
}

</style>
