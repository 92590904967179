<template>
  <div class="thumb">
    <div class="thumb__link">
      <router-link :to="data.link">
        <Book
          :data="{ ...data.acf.book, index: data.index }"
          class="thumb__book"
        />
      </router-link>
      <div class="thumb__info t-center">
        <Title
          :data="{
            value:
              data.acf && data.acf.title ? data.acf.title : data.title.rendered,
            size: 'ms',
          }"
          class="t-uppercase"
        />

        <Cta :data="{ title: 'Read more', size: 's', url: data.link }" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Book from '@/components/book';

export default {
  name: 'Thumb',
  components: {
    Cta,
    Title,
    Book,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  &__book {
    position: relative;
  }
  &__info {
    margin-top: 20px;
    .cta {
      margin-top: 8px;
    }
  }
}

.desktop {
  display: none;
  @include mq(m) {
    display: block;
  }
}

.mobile {
  @include mq(m) {
    display: none;
  }
}
</style>
