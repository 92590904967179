<template>
  <transition
    name="slide-up"
    appear
  >
    <div class="infobox">
      <div class="infobox-container">
        <Title
          v-if="infobox.title"
          :data="{ value: infobox.title, size: 'sm' }"
        />
        <RichText
          v-if="infobox.textarea"
          :data="{ value: infobox.textarea, size: 's' }"
        />
        <Cta
          v-if="infobox.link"
          :data="{ ...infobox.link, size: 'xxs' }"
        />

        <div class="paws">
          <inline-svg :src="require(`@/assets/svg/paw-left.svg`)" />
          <inline-svg :src="require(`@/assets/svg/paw-right.svg`)" />
        </div>

        <button
          class="close"
          @click="$store.commit('CLOSE_INFOBOX')"
        >
          <inline-svg :src="require(`@/assets/svg/icons/close.svg`)" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Infobox',
  components: {
    Title,
    RichText,
    Cta,
  },
  computed: {
    ...mapGetters(['options']),
    infobox() {
      return this.options.infobox;
    },
  },
};
</script>

<style lang="scss" scoped>
.infobox {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;

  &-container {
    position: relative;
    text-align: center;
    padding: 20px 40px;
    width: 620px;
    max-width: 80%;
    background: var(--white);
    border: 2px solid currentColor;
    border-bottom: 0;

    @include mq(s) {
      padding: 38px 68px;
      max-width: 100%;
    }

    @include mq($and: $hover) {
      transform: translate3d(0, 10px, 0);
      transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);

      &:hover {
        transform: translate3d(0, 0, 0);
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -30px;
      width: 30px;
      height: 100%;
    }

    & > * {
      & + * {
        margin-top: 15px;
      }
    }
  }

  .close {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    margin-top: 0;

    right: -10px;
    top: 0;
    transform: translate3d(100%, 0, 0);

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      color: var(--red);
    }
  }

  .paws {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin-top: 0;

    svg {
      position: absolute;
      top: 55%;
      height: 130px;

      &:first-child {
        transform: translate3d(-50%, -50%, 0);
        left: 0;
      }
      &:last-child {
        transform: translate3d(50%, -50%, 0);
        right: 0;
      }
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.slide-up-enter-active {
  transition-delay: 1s;
}
</style>
