<template>
  <nav>
    <Flex
      class="artists__flex"
      gap
      justify="space-around"
    >
      <div
        v-for="item in navigation"
        :key="item.id"
        :class="[...item.css]"
      >
        <Cta
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
          class="artist__cta"
          @mouseenter.native="setShowCover(item.id)"
          @mouseleave.native="setShowCover(false)"
        />

        <transition name="fade">
          <Figure
            v-show="showCover === item.id"
            class="artist__cover"
            :data="{
              default: item.acf.cover_artist,
            }"
          />
        </transition>
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';
import Figure from '@/components/media/figure';

export default {
  name: 'MenuArtist',
  components: {
    Cta,
    Figure,
  },
  data() {
    return {
      showCover: false,
    };
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('artists');
      return nav ? nav.items : null;
    },
  },
  beforeDestroy() {
    this.showCover = false;
  },
  methods: {
    setShowCover(value) {
      if (!this.$mq.isTouchDevice) {
        this.showCover = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.artists__flex {
  flex-direction: column;
  @include mq(s) {
    flex-direction: row;
  }
  @include mq(m) {
    column-gap: 80px;
  }
}

.artist__cta {
  position: relative;
  z-index: 2;
}

.artist__cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include mq($and: $not_hover) {
    display: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
