<template>
  <nav class="nav">
    <Flex
      gap
      justify="end"
    >
      <div
        v-for="item in navigation"
        :key="item.id"
        :class="item.css"
      >
        <Cta
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
        />
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Cta from '@/components/typo/cta';

export default {
  name: 'Menu',
  components: {
    Cta,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  @include mq($until: m) {
    transition: all 0.2s ease-in-out;
    position: fixed;
    top: 5px;
    z-index: 1000;
    width: calc(100% - 20px);
    transform: translateY(-23px);
  }

  @include mq(m) {
    // position: static;
    // width: auto;
    // transform: translateY(-4px);
  }
  .flex {
    justify-content: space-between;
    @include mq(m) {
      justify-content: flex-end;
    }
  }
}
.nav__mobile--open {
  transform: translateY(0px);
}
.menu__contacts {
  @include mq(m) {
    z-index: 100000;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-1.5vw, 4px);
  }
}
.menu__artists {
  @include mq(m) {
    top: 0;
    left: 0;
    z-index: 100000;
    position: absolute;
    right: auto;
    transform: translate(+14.8vw, 4px);
  }
  @include mq(l) {
    transform: translate(+15.8vw, 4px);
  }
}
.menu__exhibitions {
  @include mq(m) {
    left: 0;
    top: 0;
    z-index: 100000;
    position: absolute;
    right: auto;
    transform: translate(+27.3vw, 4px);
  }
}
.menu__books {
  @include mq(m) {
    left: 0;
    top: 0;
    z-index: 100000;
    position: absolute;
    right: auto;
    transform: translate(73.05vw, 4px);
  }
}
.menu__fairs {
  @include mq(m) {
    left: 0;
    top: 0;
    z-index: 100000;
    position: absolute;
    right: auto;
    transform: translate(52.05vw, 4px);
  }
}
</style>
