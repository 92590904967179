var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{staticClass:"exhibitions",attrs:{"col":"12","row-gap":_vm.$mq.isMobile ? 'xl' : 'xxl'}},[_c('Archive',{attrs:{"data":{
      acf_fc_layout: 'archive',
      archive: 'post',
      archive_type: 'archive',
      layout: [
        {
          layout_size: '12',
          layout_type: 'grid',
          media_query: 'default',
        },
        {
          layout_size: '4',
          layout_type: 'grid',
          media_query: 'm',
        },
      ],
      posts_per_page: '100',
      title: 'Current',
      calendar: 'current',
      thumb: 'calendar',
    }}}),_c('Archive',{attrs:{"data":{
      acf_fc_layout: 'archive',
      archive: 'post',
      archive_type: 'archive',
      layout: [
        {
          layout_size: '12',
          layout_type: 'grid',
          media_query: 'default',
        },
        {
          layout_size: '4',
          layout_type: 'grid',
          media_query: 'm',
        },
      ],
      posts_per_page: '100',
      title: 'Upcoming',
      calendar: 'upcoming',
      thumb: 'calendar',
    }}}),_c('Archive',{attrs:{"data":{
      acf_fc_layout: 'archive',
      archive: 'post',
      archive_type: 'archive',
      layout: [
        {
          layout_size: '12',
          layout_type: 'grid',
          media_query: 'default',
        },
        {
          layout_size: '4',
          layout_type: 'grid',
          media_query: 'm',
        },
      ],
      posts_per_page: '100',
      title: 'Past',
      calendar: 'past',
      thumb: 'calendar',
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }