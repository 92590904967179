<template>
  <span class="availability">
    <div
      v-if="isOos"
      class="availability__oos"
    >
      {{ $labels.out_of_stock }}
    </div>
    <!-- <div v-else>
      {{ product.stock_quantity }}
      {{ $labels.available }}
    </div> -->
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { isOos } from '@/assets/js/utils-wc';

export default {
  name: 'Price',
  components: {
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['wcSettings']),
    isOos() {
      return isOos(this.product);
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.availability__oos {
  color: var(--warning-color);
}
</style>
