<template>
  <div
    id="app"
    :class="$route.path === '/contacts/' ? 'app__contacts' : null"
  >
    <MenuArtist
      ref="menu"
      class="artists"
    />
    <div class="allbody">
      <AppHeader />
      <transition
        :css="false"
        appear
        mode="out-in"
        @enter="enter"
        @leave="leave"
      >
        <router-view :key="$route.path" />
      </transition>
      <AppFooter />
    </div>
    <Cookie />

    <Infobox
      v-if="
        options.infobox &&
          options.infobox.active &&
          showInfobox &&
          !toggleArtist
      "
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import MenuArtist from '@/components/ui/menu-artist';

// import Modal from '@/components/ui/modal';
// import Snackbar from '@/components/ui/snackbar';
// import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';
import Infobox from '@/components/ui/infobox';

// import Cart from '@/components/wc/cart';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    MenuArtist,
    // Modal,
    Cookie,
    // Loader,
    // Snackbar,
    // Cart,
    Infobox,
  },
  mixins: [lazyload],
  data() {
    return {
      timeout: false,
      timer: false,
      firstTimer: true,
      toggleArtist: false,
      artistHeight: 0,
      stopTimer: false,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options', 'showInfobox']),
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        if (this.toggleArtist) {
          this.openArtists();
        }

        setTimeout(() => {
          if (document.querySelector('.app__contacts')) {
            document.body.classList.add('body__contacts');
          } else {
            document.body.classList.remove('body__contacts');
          }
        }, 10);
      }
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (document.querySelector('.menu__artists')) {
      document
        .querySelector('.menu__artists')
        .addEventListener('click', this.openArtists);
    }
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
        if (document.querySelector('.menu__artists')) {
          this.moveAllbody();
        }
      }, 400),
    );

    if (document.querySelector('.app__contacts')) {
      document.body.classList.add('body__contacts');
    } else {
      document.body.classList.remove('body__contacts');
    }

    if (this.firstTimer) {
      this.startTimer();
    }

    document.addEventListener('scroll', this.removeLupetti);

    document.addEventListener('mousemove', this.startTimer);
    // document.addEventListener('scroll', this.startTimer);

    // document.onmousemove = function () {
    //   this.startTimer();
    // };

    this.$bus.$on('videoPlaying', this.videoPlaying);
    this.$bus.$on('videoPaused', this.videoPaused);
  },
  methods: {
    videoPlaying() {
      this.stopTimer = true;
      this.removeLupetti();
    },
    videoPaused() {
      this.stopTimer = false;
    },
    moveAllbody() {
      const artistHeight = document.querySelector('.artists').clientHeight + 10;
      this.artistHeight = artistHeight;
      const artistHeightPX = this.toggleArtist ? `${artistHeight}px` : '0px';
      // const translation = `translateY(${artistHeightPX})`;
      document.documentElement.style.setProperty(
        '--artist-menu',
        artistHeightPX,
      );
      document.querySelector('.allbody').style.marginTop = artistHeightPX;
    },
    openArtists(e) {
      if (e) {
        e.preventDefault();
      }
      if (
        e
        && (e.target === this.$refs.menu.$el
          || this.$refs.menu.$el.contains(e.target))
      ) return;

      this.toggleArtist = !this.toggleArtist;

      this.moveAllbody();
      document.querySelector('.artists').classList.toggle('artists--open');
      document.querySelector('.allbody').classList.toggle('allbody--open');
      document.body.classList.toggle('artists-opened');

      if (this.toggleArtist) {
        setTimeout(() => {
          document.body.addEventListener('click', this.openArtists);
        }, 1);
      } else {
        document.body.removeEventListener('click', this.openArtists);
      }
    },
    closeArtists(force = false) {
      this.toggleArtist = false;
      if (force) {
        document.querySelector('.artists').classList.add('close--force');
        document.querySelector('.allbody').classList.add('close--force');
      }

      document.querySelector('.allbody').style.marginTop = 0;

      document.querySelector('.artists').classList.remove('artists--open');
      document.querySelector('.allbody').classList.remove('allbody--open');
      document.body.classList.remove('artists-opened');

      document.body.removeEventListener('click', this.openArtists);

      if (force) {
        this.$nextTick(() => {
          document.querySelector('.artists').classList.remove('close--force');
          document.querySelector('.allbody').classList.remove('close--force');
        });
      }
    },
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },

    startTimer() {
      clearTimeout(this.timeout);
      clearInterval(this.timer);

      function removeElementsByClass(className) {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
      if (!this.stopTimer) {
        removeElementsByClass('lupetto');

        if (window.innerWidth > 1000) {
          this.timeout = setTimeout(() => {
            this.timer = setInterval(() => {
              const newDiv = document.createElement('div');
              newDiv.className = 'lupetto';
              newDiv.style.left = `${Math.floor(
                Math.random() * (1 + 100 - 0),
              )}vw`;
              newDiv.style.top = `${Math.floor(
                Math.random() * (1 + 100 - 0),
              )}vh`;
              document.querySelector('#app').appendChild(newDiv);
            }, 2000);
          }, 4000);
        }
        this.firstTimer = false;
      }
    },
    removeLupetti() {
      // console.log('removelupetti');

      clearTimeout(this.timeout);
      clearInterval(this.timer);

      function removeElementsByClass(className) {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
      removeElementsByClass('lupetto');

      this.startTimer();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-loaded {
  #loader {
    display: none;
  }
}
#app {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh-fixed, 1vh) * 100);

  main {
    flex: 1 0 0;
  }
}
.lupetto {
  position: fixed;
  z-index: 1000;
  height: 66px;
  width: 66px;
  background-image: url("~@/assets/svg/lupetto.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
