var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"cookie","appear":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.cookie && _vm.init),expression:"!cookie && init"}],staticClass:"cookie"},[_c('Flex',[(_vm.options.cookies.title)?_c('Label',{attrs:{"data":{
          value: _vm.options.cookies.title,
        }}}):_vm._e(),(_vm.options.cookies.text)?_c('Richtext',{attrs:{"data":{
          value: _vm.options.cookies.text,
        }}}):_vm._e(),(_vm.options.cookies.cta)?_c('Cta',{attrs:{"data":{
          title: _vm.options.cookies.cta,
          fn: _vm.acceptCookie,
        }}}):_vm._e(),_c('Icon',{attrs:{"name":"close","size":"s","theme":"minimal","fn":_vm.acceptCookie}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }