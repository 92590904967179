<template>
  <div class="thumb">
    <router-link
      :to="data.link"
      class="thumb__link"
    >
      <Figure
        v-if="data.acf.thumb_mobile"
        class="mobile"
        :data="data.acf.thumb_mobile"
      />

      <Figure
        v-if="data.gds_featured_image"
        class="desktop"
        :data="data.gds_featured_image"
      />
      <div class="thumb__info">
        <div class="thumb__info--box">
          <!-- {{ data.acf }} -->
          <div class="thumb__info--box--inside">
            <div>
              <p
                v-if="artists"
                class="thumb__artist"
                v-html="artists"
              />

              <Title :data="{ value: data.taxonomy ? data.name : data.title.rendered }" />
            </div>
            <p class="thumb__dates">
              {{ data.acf.thumb_dates }}
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    artists() {
      if (this.data.acf.artists) {
        const array = this.data.acf.artists.map((el) => (el.artist_page ? el.artist.post_title : el.artist_title));
        return array.join(', ');
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  @include mq($until: m) {
    height: calc((var(--vh-fixed) * 100) - 88px);
  }

  @include mq($and: $hover) {
    &:hover {
      a {
        color: black;
      }
    }
  }

  &__link {
    display: block;
    width: fit-content;
    width: 100%;
    height: 100%;
    position: relative;

    @include mq(m) {
      height: fit-content;
    }

    @include mq($until: m) {
      display: flex;
      flex-direction: column;
    }
  }

  &__info {
    text-transform: uppercase;
    .thumb__dates {
      margin-top: 10px;
    }
    .title {
      font-style: italic;
      @extend %typo--m;
    }
    &--box {
      background: white;
      padding: 25px 10px 0px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      &--inside {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 10px;
      }

      @include mq($and: $hover) {
        &:hover {
          * {
            color: red;
          }
        }
      }
    }
    @include mq(m) {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: grid;
      grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
      grid-column-gap: 10px;
      &--box {
        background: white;
        grid-column: 4/10;
        // min-height: 137px;
      }
    }
    @include mq(l) {
      &--box {
        grid-column: 5/9;
      }
    }
  }
  &__artist {
    @extend %typo--m;
    text-transform: uppercase;
  }
}
.picture {
  width: 100%;
  // height: calc(100vw * 4 / 3);
  height: 100%;
  @include mq(s) {
    width: 100%;
    // height: calc(100vw * 9 / 16);
    height: calc((var(--vh-fixed, 1vh) * 100) - 10px - 8vw);
  }
}

.desktop {
  display: none;
  @include mq(m) {
    display: block;
  }
}

.mobile {
  @include mq(m) {
    display: none;
  }
}
</style>
