<template>
  <transition
    name="cookie"
    appear
  >
    <div
      v-show="!cookie && init"
      class="cookie"
    >
      <Flex>
        <Label
          v-if="options.cookies.title"
          :data="{
            value: options.cookies.title,
          }"
        />
        <Richtext
          v-if="options.cookies.text"
          :data="{
            value: options.cookies.text,
          }"
        />
        <Cta
          v-if="options.cookies.cta"
          :data="{
            title: options.cookies.cta,
            fn: acceptCookie,
          }"
        />
        <Icon
          name="close"
          size="s"
          theme="minimal"
          :fn="acceptCookie"
        />
      </Flex>
    </div>
  </transition>
</template>

<script>
import { bootstrap } from 'vue-gtag';
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { loadScript } from '@/assets/js/utils';

import Icon from '@/components/ui/icon';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Label from '@/components/typo/label';

// eslint-disable-next-line no-undef
const id = __VUE_WORDPRESS__.state.options?.cookies?.id;

export default {
  name: 'Cookie',
  components: {
    Icon,
    Richtext,
    Label,
    Cta,
  },
  data() {
    return {
      init: true,
      cookie: !!Cookies.get(id),
    };
  },
  computed: {
    ...mapGetters(['transition', 'options']),
  },
  watch: {
    transition(val) {
      if (!val) this.init = true;
    },
  },
  methods: {
    acceptCookie() {
      Cookies.set(this.options.cookies.id, '1', { expires: 365 });
      this.cookie = true;

      if (this.options.scripts && this.options.scripts.scripts_repeater) {
        this.options.scripts.scripts_repeater.forEach((item) => {
          if (item.inline) {
            const s = document.createElement('span');
            s.style.display = 'none';
            s.innerHTML = item.script;
            document.getElementsByTagName('body')[0].appendChild(s);
          } else {
            loadScript(item.script_src);
          }
        });
      }

      if (this.$gtag) {
        bootstrap();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-enter-active,
.cookie-leave-active {
  transition: all 0.8s $ease-custom;
}
.cookie-enter,
.cookie-leave-to {
  transform: translateY(100%) translateY(30px);
}
.cookie {
  background: black;
  color: white;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 2000;
  left: $unit;
  bottom: $unit;
  width: calc(100% - #{$unit * 2});
  padding: 8px 8px 6px 10px;
  @extend %typo--xs;
  .flex {
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
.icon {
  margin-left: 20px;
  // background: chartreuse;
  mix-blend-mode: difference;
  //   path{
  //   fill: white;
  // }
}
.svg {
  background: red;
}
</style>
