<template>
  <div class="video-player">
    <div
      ref="player"
      class="plyr"
      v-html="data"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

export default {
  name: 'VideoPlayer',
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      plyr: null,
    };
  },
  mounted() {
    this.plyr = new Plyr(this.$refs.player, {
      autoplay: true,
      muted: true,
      controls: [
        'play-large',
        'play',
        'progress',
        'mute',
        'volume',
        'fullscreen',
      ],
      iconUrl: `${process.env.VUE_APP_BASE_URL}wp-content/themes/gds-vue/static/svg/plyr.svg`,
      storage: { enabled: false },
    });

    this.plyr.on('play', this.play);

    this.plyr.on('pause', this.pause);
  },
  destroyed() {
    this.plyr.off('play', this.play);

    this.plyr.off('pause', this.pause);
  },
  methods: {
    play() {
      this.$bus.$emit('videoPlaying');
    },
    pause() {
      this.$bus.$emit('videoPaused');
    },
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: $color-white;
  --plyr-range-track-height: 2px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-thumb-shadow: none;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type="range"] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
}

.plyr__controls {
  background: none;
  mix-blend-mode: difference;
}
.plyr--stopped .plyr__controls {
  display: none;
}
.plyr--video:not(.plyr--stopped) .plyr__control.plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}
.plyr__control.plyr__control--overlaid {
  transform: translate(-50%, -50%) scale(4);
  mix-blend-mode: difference;
  @include mq(xxs) {
    transform: translate(-50%, -50%) scale(5.5);
  }
  svg {
    left: 0;
    position: static;
  }
}

.plyr--fullscreen-fallback {
  display: flex;
}

.safari {
  .plyr--fullscreen-enabled {
    .plyr__video-wrapper.plyr__video-embed {
      padding-bottom: 56.25%;
      height: 0 !important;
    }
  }
}
</style>
