<template>
  <div
    v-if="data.slide.length > 0"
    class="hero"
  >
    <div
      v-if="data.slide.length === 1"
      class="item"
    >
      <Content
        v-if="checkContent(data.slide[0])"
        :data="setContent(data.slide[0])"
        class="content"
      />
      <Media
        v-if="checkMedia(data.slide[0])"
        :data="setMedia(data.slide[0])"
        class="media"
      />
    </div>

    <Carousel
      v-else
    >
      <div
        v-for="(item, index) in data.slide"
        :key="index"
        class="item"
      >
        <Content
          v-if="checkContent(item)"
          :data="setContent(item)"
          class="content"
        />
        <Media
          v-if="checkMedia(item)"
          :data="setMedia(item)"
          class="media"
        />
      </div>
    </Carousel>
  </div>
</template>

<script>
import {
  setContent, setMedia, checkContent, checkMedia,
} from '@/assets/js/utils';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';

export default {
  name: 'Hero',
  components: {
    Content,
    Media,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
    };
  },
};
</script>

<style lang="scss" scoped>
.item {
  position: relative;

  .media {
    position: relative;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    background: var(--white);
    padding: var(--spacer-xxl);
  }
}
</style>
