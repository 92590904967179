var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",style:({
    '--width': `${_vm.scale * _vm.data.width}px`,
    '--height': `${_vm.scale * _vm.data.height}px`,
    '--depth': `${_vm.scale * _vm.data.depth}px`,
    '--speed': `${_vm.data.index + 10}s`,
    '--direction': _vm.data.index % 2 === 0 ? 'normal' : 'reverse',
  })},[_c('div',{staticClass:"boockup"},[_c('div',{staticClass:"book-container",style:({ backgroundColor: _vm.data.background })},[_c('div',{staticClass:"book-front",style:({ backgroundImage: `url(${_vm.data.cover.url})` })}),_c('div',{staticClass:"book-side-left",style:({ backgroundImage: `url(${_vm.data.spine.url})` })}),_c('div',{staticClass:"book-side-right"}),_c('div',{staticClass:"book-top"}),_c('div',{staticClass:"book-bottom"}),_c('div',{staticClass:"book-back",style:({ backgroundImage: `url(${_vm.data.back.url})` })})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }