<template>
  <Spacer class="t-center">
    <div>
      <inline-svg
        :src="require(`@/assets/svg/coming-soon.svg`)"
        class="soon-svg"
      />
      <Title
        v-if="data.artist"
        :data="{ value: data.artist }"
        class="artist"
      />
      <Title
        v-if="data.title"
        :data="{ value: data.title }"
        class="head"
      />
      <Label
        v-if="data.subtitle"
        :data="{ value: data.subtitle }"
        class="date"
      />
      <Richtext
        v-if="data.textarea"
        :data="{ value: data.textarea }"
        class="content"
      />

      <a
        v-if="data.link"
        :href="data.link.url"
        target="_blank"
        class="link"
      >
        {{ data.link.title }} <br>
        <svg
          class="arrow"
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.25 4.125L5.125 8.25L1 4.125"
            stroke="currentColor"
          />
          <path
            d="M1 11H9.25"
            stroke="currentColor"
          />
          <path
            d="M5.125 8.25L5.125 0"
            stroke="currentColor"
          />
        </svg>
      </a>
    </div>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'ComingSoon',
  components: {
    Title,
    Label,
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.soon-svg {
  margin-bottom: 40px;
  max-width: 204px;
}
.artist {
  font-size: 22px;
  line-height: 24px;
}

.head {
  font-style: italic;
  font-size: 22px;
  line-height: 24px;
}
.date, .content {
  margin-top: 10px;
}

.link {
  margin-top: 22px;
  display: inline-block;
}
.arrow {
  margin-top: 4px;
}
</style>

<style lang="scss">
.block--coming_soon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc((var(--vh-fixed) * 100) - 78px - 30px);
  @include mq(m) {
    height: calc((var(--vh-fixed) * 100) - 8vw - 30px);
    padding-bottom: calc(var(--vh-fixed) * 4);
  }
}
</style>
