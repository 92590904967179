<template>
  <main class="main">
    <div class="single single__book">
      <Grid
        class="single__header"
        row-gap="l"
      >
        <Column :size="{ default: 12 }">
          <Title
            class="single__header--title t-uppercase"
            :data="{ value: post.acf.title }"
          />
        </Column>
        <Column
          :size="{ default: 12, m: 5 }"
          :offset="{ m: 6 }"
        >
          <RichText
            v-if="post.acf.description"
            class="single__header--desc"
            :data="{ value: post.acf.description }"
          />
        </Column>
        <Column
          :size="{ default: 12, m: 5 }"
          :offset="{ m: 6 }"
          class="informations"
        >
          <div
            v-for="info in infos"
            :key="info.id"
            class="info"
          >
            <Grid>
              <Column :size="{ default: 6, m: 3 }">
                <Label :data="{ value: info.label, size: 's' }" />
              </Column>
              <Column :size="{ default: 6, m: 9 }">
                <Label :data="{ value: info.value, size: 's' }" />
              </Column>
            </Grid>
          </div>
        </Column>
      </Grid>

      <div class="typo--s">
        <div v-if="links.length === 1">
          <div class="single__bar--artist single__bar--artist--1">
            <div class="single__bar--item single__bar--1 desktop bar--0">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999998 13.0004L1 1.00035L82 1.00037"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--1 mobile bar--0">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L1 1L21 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div
              v-for="(item1, index1) in links"
              :key="index1"
              class="bar--1"
            >
              <div class="single__bar--item single__bar--artist--item">
                <component
                  :is="item1.to ? 'router-link' : 'a'"
                  :to="item1.to ? item1.to : undefined"
                  :href="item1.url ? item1.url : undefined"
                  :target="item1.url ? '_blank' : undefined"
                >
                  {{ item1.label }}
                </component>
              </div>
            </div>
            <div class="mobile bar--2">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 13V1L-3.72529e-07 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--5 desktop bar--2">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                  stroke="black"
                />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="links.length === 2">
          <div class="single__bar--artist single__bar--artist--2">
            <div
              v-for="(item2, index2) in links"
              :key="index2"
              :class="'bar--' + index2"
            >
              <div
                v-if="index2 === 1"
                class="desktop"
              >
                <svg
                  width="162"
                  height="13"
                  viewBox="0 0 162 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0005L81 1.00047L162 1.00049"
                    stroke="black"
                  />
                  <path
                    d="M81 13.0005L81 1.00047L1.49012e-06 1.00046"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999998 13.0004L1 1.00035L82 1.00037"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div class="single__bar--item single__bar--artist--item">
                <component
                  :is="item2.to ? 'router-link' : 'a'"
                  :to="item2.to ? item2.to : undefined"
                  :href="item2.url ? item2.url : undefined"
                  :target="item2.url ? '_blank' : undefined"
                >
                  {{ item2.label }}
                </component>
              </div>

              <div
                v-if="index2 === links.length - 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === links.length - 1"
                class="single__bar--item single__bar--5 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                    stroke="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="post.acf.gallery && post.acf.gallery.length > 0"
        class="gallery"
      >
        <Gallery :data="post.acf.gallery" />
      </div>
    </div>
  </main>
</template>

<script>
import data from '@/mixins/data';
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import Gallery from '@/components/media/gallery';
import RichText from '@/components/typo/richtext';

export default {
  name: 'Single',
  components: {
    Title,
    RichText,
    Label,
    Gallery,
  },
  mixins: [data],
  computed: {
    infos() {
      const array = [];
      const keys = Object.keys(this.post.acf.information);
      if (keys.length > 0) {
        keys.forEach((key) => {
          if (key !== 'custom_info' && this.post.acf.information[key] !== '') {
            const label = key.charAt(0).toUpperCase() + key.slice(1);
            array.push({
              id: key,
              label,
              value: this.post.acf.information[key],
            });
          }

          if (key === 'custom_info' && this.post.acf.information.custom_info) {
            this.post.acf.information[key].forEach((custom, index) => {
              array.push({
                id: `custom-${index}`,
                label: custom.label,
                value: custom.value,
              });
            });
          }
        });
      }
      return array;
    },

    links() {
      const links = [];
      if (this.post.acf.exhibition) {
        const el = this.post.acf.exhibition;
        links.push({
          to: `/exhibitions/${el.post_name}`,
          label: 'About the exhibition',
        });
      }
      if (this.post.acf.contact_for_purchase) {
        links.push({
          url: 'mailto:info@lupo.gallery',
          label: 'Contact for purchase',
        });
      }
      return links;
    },
  },
};
</script>
<style lang="scss" scoped>
.single__book {
  padding: 0 10px;
  margin-top: 40px;
  @include mq(m) {
    margin-top: 0px;
  }

  .gallery {
    margin-top: 40px;

    @include mq(m) {
      margin-top: 60px;
    }
  }

  .info + .info {
    margin-top: 6px;
  }
}

.single__bar--artist-mobile {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-column-gap: 10px;
  .single__bar--item {
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
.single__bar--artist {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
  grid-column-gap: 10px;
  .bar--0 {
    grid-column: span 7;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 7fr 7fr 7fr 7fr 7fr 7fr 7fr;

    div {
      grid-column: span 1;
      text-align: center;
      &:nth-child(1) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(2) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(3) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(5) {
        text-align: center;
        grid-column: span 2;
      }
    }
    @include mq(m) {
      grid-column: span 5;
      grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
      div {
        grid-column: span 2;
        text-align: center;
        &:nth-child(1) {
          text-align: left;
          grid-column: span 1;
        }
        &:nth-child(3) {
          text-align: center;
          grid-column: span 2;
        }
      }
    }
  }
  .bar--1 {
    grid-column: span 5;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(3) {
        text-align: right;
        grid-column: span 1;
      }
    }
    @include mq(m) {
      grid-column: span 4;
      grid-template-columns: 2fr 2fr;

      div {
        grid-column: span 1;
        &:nth-child(1) {
          text-align: center;
          grid-column: span 1;
        }
        &:nth-child(2) {
          text-align: right;
          grid-column: span 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .bar--2 {
    grid-column: span 12;
    margin-top: 32px;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 3fr 3fr 3fr;
    div {
      grid-column: span 2;
      &:nth-child(1) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(2) {
        text-align: center;
        grid-column: span 1;
      }
      &:nth-child(3) {
        text-align: right;
        grid-column: span 1;
      }
    }

    @include mq(m) {
      grid-template-columns: 3fr 3fr 3fr;
      grid-column: span 3;
      margin-top: 0;
      .single__bar--5 {
        text-align: right;
        grid-column: span 1 !important;
      }
      div {
        grid-column: span 2;

        &:nth-child(2) {
          text-align: center;
          grid-column: span 2;
        }
        &:nth-child(3) {
          text-align: right;
          grid-column: span 1;
        }
      }
    }
  }
  a {
    text-align: center;
  }
  .single__bar--item {
    grid-column: span 2;
  }
  .single__bar--artist--item {
    text-align: center;
    grid-column: span 2;
  }
}
.single__bar--artist--1 {
  .bar--0 {
    grid-column: span 4;
  }
  .bar--1 {
    grid-column: span 4;
    display: flex;
    justify-content: center;
  }
  .bar--2 {
    grid-column: span 4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0;
  }
  .desktop {
    display: none;
    @include mq(m) {
      display: flex;
    }
  }
}

.single__bar--artist--2 {
  .bar--0 {
    grid-column: span 4;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 6;
      }
      &:nth-child(2) {
        text-align: left;
        grid-column: span 2;
      }
      &:nth-child(3) {
        align-self: start;
        text-align: center;
        grid-column: span 5;
      }
    }
    @include mq(m) {
      grid-column: span 4;

      div {
        &:nth-child(1) {
          text-align: center;
          grid-column: span 2;
        }
        &:nth-child(2) {
          text-align: center;
          grid-column: span 6;
        }
        &:nth-child(3) {
          text-align: center;
          grid-column: span 3;
        }
      }
    }
  }
  .bar--1 {
    grid-column: span 8;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(2) {
        // background: blue;
        text-align: center;
        grid-column: span 2;
      }

      &:nth-child(3) {
        // background: cyan;
        text-align: right;
        grid-column: span 2;
        display: flex;
        justify-content: center;
      }
      &:nth-child(4) {
        // background: orange;
        text-align: right;
        grid-column: span 1;
        display: flex;
        justify-content: flex-end;
      }
    }
    @include mq(m) {
      grid-column: span 8;
      grid-template-columns: 8fr 8fr 8fr 8fr 8fr 8fr 8fr 8fr;

      div {
        grid-column: span 1;
        &:nth-child(1) {
          text-align: center;
          grid-column: span 4;
          display: flex;
          justify-content: center;
        }
        &:nth-child(2) {
          text-align: center;
          grid-column: span 1;
          display: flex;
          justify-content: center;
          display: none;
        }
        &:nth-child(3) {
          grid-column: span 2;
          // display: none;
        }

        &:nth-child(5) {
          text-align: right;
          // background: red;
          grid-column: span 2;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.desktop {
  display: none;
  @include mq(m) {
    display: grid;
  }
}

.mobile {
  @include mq(m) {
    display: none !important;
  }
}
.single__bar--item {
  a {
    @include mq($and: $hover) {
      &:hover {
        path {
          stroke: var(--red);
        }
      }
    }
  }
}
</style>
