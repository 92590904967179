<template>
  <main class="main">
    <div
      class="single"
      :class="post.type === 'artist' ? 'single__artist' : null"
    >
      <div class="single__header">
        <div class="single__artists">
          <div
            v-for="(artist, index) in post.acf.artists"
            :key="index"
            class="single__artist__titles"
          >
            <span
              v-if="index > 0 && post.acf.artists.length > 1"
              class="single__header--artist"
            >,
            </span>

            <router-link
              v-if="artist.artist_page"
              :to="'/artist/' + artist.artist.post_name"
              class="single__header--artist"
            >
              <RichText :data="{ value: artist.artist.post_title }" />
            </router-link>

            <RichText
              v-else
              class="single__header--artist"
              :data="{ value: artist.artist_title }"
            />
          </div>
        </div>

        <Title
          class="single__header--title"
          :data="{ value: post.title.rendered }"
        />
        <RichText
          v-if="post.acf.dates"
          class="single__header--dates"
          :data="{ value: post.acf.dates }"
        />
        <RichText
          v-if="post.acf.description"
          class="single__header--desc"
          :data="{ value: post.acf.description }"
        />
      </div>

      <div v-if="post.type !== 'artist'">
        <div v-if="informationPost.length === 1">
          <div class="single__bar--artist single__bar--artist--1">
            <div class="single__bar--item single__bar--1 desktop bar--0">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999998 13.0004L1 1.00035L82 1.00037"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--1 mobile bar--0">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L1 1L21 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div
              v-for="(item1, index1) in informationPost"
              :key="index1"
              class="bar--1"
            >
              <div class="single__bar--item single__bar--artist--item">
                <a
                  :href="item1.url"
                  target="_blank"
                >
                  {{ item1.label }} <br>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.25 4.125L5.125 8.25L1 4.125"
                      stroke="black"
                    />
                    <path
                      d="M1 11H9.25"
                      stroke="black"
                    />
                    <path
                      d="M5.125 8.25L5.125 0"
                      stroke="black"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div class="mobile bar--2">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 13V1L-3.72529e-07 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--5 desktop bar--2">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                  stroke="black"
                />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="informationPost.length === 2">
          <div class="single__bar--artist single__bar--artist--2">
            <div
              v-for="(item2, index2) in informationPost"
              :key="index2"
              :class="'bar--' + index2"
            >
              <div
                v-if="index2 === 1"
                class="desktop"
              >
                <svg
                  width="162"
                  height="13"
                  viewBox="0 0 162 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0005L81 1.00047L162 1.00049"
                    stroke="black"
                  />
                  <path
                    d="M81 13.0005L81 1.00047L1.49012e-06 1.00046"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999998 13.0004L1 1.00035L82 1.00037"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div class="single__bar--item single__bar--artist--item">
                <a
                  :href="item2.url"
                  target="_blank"
                >{{ item2.label }}<br>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.25 4.125L5.125 8.25L1 4.125"
                      stroke="black"
                    />
                    <path
                      d="M1 11H9.25"
                      stroke="black"
                    />
                    <path
                      d="M5.125 8.25L5.125 0"
                      stroke="black"
                    />
                  </svg>
                </a>
              </div>

              <div
                v-if="index2 === informationPost.length - 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === informationPost.length - 1"
                class="single__bar--item single__bar--5 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                    stroke="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-if="informationPost.length === 3">
          <div class="single__bar--artist">
            <div
              v-for="(item, index) in informationPost"
              :key="index"
              :class="'bar--' + index"
            >
              <div
                v-if="index === 0"
                class="single__bar--item single__bar--1 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999998 13.0004L1 1.00035L82 1.00037"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === 0 || index === 2"
                class="single__bar--item single__bar--1 mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div class="single__bar--item single__bar--artist--item">
                <a
                  :href="item.url"
                  target="_blank"
                >
                  {{ item.label }}<br>
                  <svg
                    width="10"
                    height="12"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.25 4.125L5.125 8.25L1 4.125"
                      stroke="black"
                    />
                    <path
                      d="M1 11H9.25"
                      stroke="black"
                    />
                    <path
                      d="M5.125 8.25L5.125 0"
                      stroke="black"
                    />
                  </svg>
                </a>
              </div>

              <div
                v-if="index < informationPost.length - 1"
                class="desktop"
              >
                <svg
                  width="162"
                  height="13"
                  viewBox="0 0 162 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0005L81 1.00047L162 1.00049"
                    stroke="black"
                  />
                  <path
                    d="M81 13.0005L81 1.00047L1.49012e-06 1.00046"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index < 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === informationPost.length - 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === informationPost.length - 1"
                class="single__bar--item single__bar--5 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                    stroke="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="post.type === 'artist'">
        <div v-if="informationWithId.length === 1">
          <div class="single__bar--artist single__bar--artist--1">
            <div class="single__bar--item single__bar--1 desktop bar--0">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.999998 13.0004L1 1.00035L82 1.00037"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--1 mobile bar--0">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L1 1L21 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div
              v-for="(item1, index1) in informationWithId"
              :key="index1"
              class="bar--1"
            >
              <div class="single__bar--item single__bar--artist--item">
                <a :href="'#' + item1.id">{{ item1.label }}</a>
              </div>
            </div>
            <div class="mobile bar--2">
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 13V1L-3.72529e-07 1"
                  stroke="black"
                />
              </svg>
            </div>

            <div class="single__bar--item single__bar--5 desktop bar--2">
              <svg
                width="82"
                height="13"
                viewBox="0 0 82 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                  stroke="black"
                />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="informationWithId.length === 2">
          <div class="single__bar--artist single__bar--artist--2">
            <div
              v-for="(item2, index2) in informationWithId"
              :key="index2"
              :class="'bar--' + index2"
            >
              <div
                v-if="index2 === 1"
                class="desktop"
              >
                <svg
                  width="162"
                  height="13"
                  viewBox="0 0 162 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0005L81 1.00047L162 1.00049"
                    stroke="black"
                  />
                  <path
                    d="M81 13.0005L81 1.00047L1.49012e-06 1.00046"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999998 13.0004L1 1.00035L82 1.00037"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 0"
                class="single__bar--item single__bar--1 mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div class="single__bar--item single__bar--artist--item">
                <a :href="'#' + item2.id">{{ item2.label }}</a>
              </div>

              <div
                v-if="index2 === informationWithId.length - 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index2 === informationWithId.length - 1"
                class="single__bar--item single__bar--5 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                    stroke="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div v-if="informationWithId.length === 3">
          <div class="single__bar--artist">
            <div
              v-for="(item, index) in informationWithId"
              :key="index"
              :class="'bar--' + index"
            >
              <div
                v-if="index === 0"
                class="single__bar--item single__bar--1 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999998 13.0004L1 1.00035L82 1.00037"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === 0 || index === 2"
                class="single__bar--item single__bar--1 mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div class="single__bar--item single__bar--artist--item">
                <a :href="'#' + item.id">{{ item.label }}</a>
              </div>

              <div
                v-if="index < informationWithId.length - 1"
                class="desktop"
              >
                <svg
                  width="162"
                  height="13"
                  viewBox="0 0 162 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0005L81 1.00047L162 1.00049"
                    stroke="black"
                  />
                  <path
                    d="M81 13.0005L81 1.00047L1.49012e-06 1.00046"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index < 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L1 1L21 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === informationWithId.length - 1"
                class="mobile"
              >
                <svg
                  width="21"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 13V1L-3.72529e-07 1"
                    stroke="black"
                  />
                </svg>
              </div>

              <div
                v-if="index === informationWithId.length - 1"
                class="single__bar--item single__bar--5 desktop"
              >
                <svg
                  width="82"
                  height="13"
                  viewBox="0 0 82 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M81 13.0006L81 1.0006L1.49012e-06 1.00058"
                    stroke="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="post.acf.gallery && post.acf.gallery.length > 0"
        class="single__galleries"
      >
        <div
          v-if="post.type === 'artist'"
          class="title artworks__title"
        >
          Artworks
        </div>
        <div
          v-for="(item, index) in post.acf.gallery"
          :key="index"
          class="item"
          :data-item-id="item.id"
          :class="'gallery__block gallery--' + item.layout"
        >
          <div
            v-if="item.layout !== 'video'"
            :class="'single__gallery single__gallery--' + item.layout"
          >
            <div
              v-for="(item2, index2) in item.images"
              :key="index2"
              class="single__gallery--layout"
              :data-item-id="item.id"
            >
              <Figure
                v-if="item2"
                class="single__gallery--image"
                :data="item2"
              />
              <RichText
                v-if="item2.caption"
                class="caption"
                :data="{ value: item2.caption, size: 'xs' }"
              />
            </div>
          </div>

          <div v-else>
            <Video :data="item.video.url" />
            <RichText
              v-if="item.video.caption"
              class="caption"
              :data="{ value: item.video.caption, size: 'xs' }"
            />
          </div>
        </div>
      </div>

      <div
        v-if="post.type === 'artist'"
        class="single__exhibitions"
      >
        <div v-if="post.acf.exhibitions">
          <h3>
            Exhibitions at Lupo
          </h3>
          <div class="single__exhibitions--all">
            <div
              v-for="(item, index) in post.acf.exhibitions"
              :key="index"
              class="single__exhibitions--single"
            >
              <div>
                <router-link
                  :to="'/exhibitions/' + item.exhibition.post_name"
                  class="single__exhibitions--link"
                >
                  <Figure
                    v-if="item.exhibition.gds_featured_image"
                    class="single__exhibitions--figure"
                    :data="item.exhibition.gds_featured_image"
                  />
                  <div class="single__exhibitions--text">
                    <Title
                      class="single__header--title"
                      :data="{ value: post.title.rendered }"
                    />
                    <Title
                      class="single__header--title italic"
                      :data="{ value: item.exhibition.post_title }"
                    />

                    <RichText
                      v-if="item.exhibition.acf.thumb_dates"
                      class="single__exhibitions--dates"
                      :data="{ value: item.exhibition.acf.thumb_dates }"
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="post.type === 'artist'"
        id="artist__info"
        class="single__information"
      >
        <div
          v-for="(item, index) in post.acf.information"
          :id="item.id"
          :key="index"
          class="single__artisti--info"
        >
          <div :class="item.id">
            <Title
              v-if="item.label"
              class="single__information--label"
              :data="{ value: item.label, size: 'ml' }"
            />
            <RichText
              v-if="item.text"
              class="single__information--text"
              :data="{ value: item.text }"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Figure from '@/components/media/figure';
import Video from '@/components/media/video';
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Figure,
    Video,
    Title,
    RichText,
  },
  mixins: [data],
  computed: {
    informationWithId() {
      return this.post.acf.information.filter((info) => info.id);
    },
    informationPost() {
      const info = [];
      if (this.post.acf.poster_file) {
        const el = this.post.acf.poster_file;
        el.label = 'Download press release';
        info.push(this.post.acf.poster_file);
      }
      if (this.post.acf.exhibition_text_file) {
        const el = this.post.acf.exhibition_text_file;
        el.label = 'Download exhibition text';
        info.push(this.post.acf.exhibition_text_file);
      }
      if (this.post.acf.artworks_file) {
        const el = this.post.acf.artworks_file;
        el.label = 'Artworks';
        info.push(this.post.acf.artworks_file);
      }
      return info;
    },
  },
  mounted() {
    this.captionNumber();
    window.addEventListener('resize', this.captionNumber);
  },
  methods: {
    captionNumber() {
      document.querySelectorAll('.gallery__block').forEach((item) => {
        const captions = item.querySelectorAll('.caption');
        if (captions.length > 1) {
          for (let i = 0; i < captions.length; i += 1) {
            if (window.innerWidth > 999) {
              if (
                i > 0
                && captions[i].innerHTML === captions[i - 1].innerHTML
              ) {
                captions[i].classList.add('uguale__desktop');
              }
            } else if (
              i < captions.length - 1
              && captions[i].innerHTML === captions[i + 1].innerHTML
            ) {
              captions[i].classList.add('uguale__mobile');
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.italic {
  font-style: italic !important;
}

.single__artist {
  .single__galleries {
    .item:nth-child(2) {
      margin-top: 0 !important;
    }
  }
}

.single {
  padding: 0 10px;
  margin-top: 40px;
  @include mq(m) {
    margin-top: 0px;
  }
  &__header {
    display: grid;
    grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
    grid-column-gap: 10px;
    &--artist {
      grid-column: 1/13;
      @extend %typo--xl;
      text-transform: uppercase;
      @include mq($and: $hover) {
        &a:hover {
          color: var(--red);
        }
      }

      div {
        @extend %typo--xl;
      }
    }
    &--title {
      grid-column: 1/13;
      @extend %typo--xl;

      text-transform: uppercase;
      font-style: italic;
    }
    &--dates {
      grid-column: 1/13;
      @extend %typo--ml;
      @include mq(m) {
        grid-column: 2/9;
      }
      @include mq(l) {
        grid-column: 2/7;
      }
    }
    &--desc {
      grid-column: 1/13;
      margin-top: 24px;
      grid-row-gap: 22px;
      @include mq(s) {
        grid-column: 1/11;
      }

      @include mq(m) {
        grid-column: 2/10;
        margin-top: 40px;
      }
      @include mq(l) {
        grid-column: 7/13;
        grid-row: 4;
        margin-top: 0px;

        transform: translateY(-20px);
      }
    }
  }
  &__bar {
    margin-top: 72px;
    display: grid;
    grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
    grid-column-gap: 10px;
    a {
      text-align: center;
    }
    &--item {
      grid-column: span 1;
      @include mq(m) {
        grid-column: span 1;
      }
    }

    &--2,
    &--4 {
      grid-column: span 4;
      @include mq(m) {
        grid-column: span 2;
      }
    }
    &--3 {
      grid-column: span 2;
      @include mq(m) {
        grid-column: span 2;
      }
    }
    &--2,
    &--3,
    &--4,
    &--5,
    &--6 {
      display: flex;
      justify-content: center;
    }
    &--5 {
      grid-column: span 1;
      @include mq(m) {
        grid-column: span 2;
      }
    }
    &--6 {
      margin-top: 14px;
      grid-column: span 10;
      @include mq(m) {
        margin-top: 0px;

        grid-column: span 2;
      }
    }
    &--7 {
      margin-top: 14px;
      display: flex;
      justify-content: flex-end;
      grid-column: span 1;
      @include mq(m) {
        margin-top: 0px;
      }
    }
    &--12,
    &--15 {
      margin-top: 14px;
    }
  }
  .single__gallery--layout {
    margin-top: 10px;
    @include mq(m) {
      margin-top: 0px;
    }
  }

  .single__galleries {
    margin-top: 90px;
    .title {
      @extend %typo--l;

      &.artworks__title {
        @extend %typo--ml;
      }
    }

    .single__gallery {
    }
    .item:not(:first-child) {
      // border:3px solid red;
      margin-top: 90px;
    }
  }

  .gallery--staggered {
    &:nth-child(odd) {
      .single__gallery--layout:nth-child(1) {
        grid-column: span 5;
        .single__gallery--image {
          width: 100%;
          // height: calc(100vw * 4 / 3);
          height: calc(100vw * 9 / 16);
        }
        // @include mq(m) {
        //   .single__gallery--image {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
      }
      .single__gallery--layout:nth-child(2) {
        .single__gallery--image {
          width: 100%;
          height: calc(100vw * 9 / 16);
        }
        grid-column: span 7;
        // @include mq(m) {
        //   .single__gallery--image {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
      }
    }
    &:nth-child(even) {
      .single__gallery--layout:nth-child(1) {
        .single__gallery--image {
          width: 100%;
          height: calc(100vw * 9 / 16);
        }
        grid-column: span 7;
        // @include mq(m) {
        //   .single__gallery--image {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
      }
      .single__gallery--layout:nth-child(2) {
        .single__gallery--image {
          width: 100%;
          height: calc(100vw * 9 / 16);
        }
        grid-column: span 5;
      }
    }
  }
  &__gallery {
    &--image {
      width: 100px;
      height: 100px;
    }

    &--full {
      // background: red;
      .single__gallery--image {
        width: calc(100vw - 20px);
        height: calc(100vw * 9 / 16);
      }
      .caption {
        margin-bottom: 0 !important;
      }
    }
    &--half {
      .single__gallery--layout:nth-child(1) {
        .caption {
          margin-bottom: 80px;
          @include mq(m) {
            margin-bottom: 0px;
          }
        }
      }

      // background: blue;
      .single__gallery--layout {
        &:nth-child(2) {
          .caption {
            margin-bottom: 0;
          }
        }
      }
      .single__gallery--image {
        width: 100%;
        height: calc(100vw * 4 / 3);
      }
      @include mq(m) {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 2fr 2fr;
        .single__gallery--image {
          width: 100%;
          height: calc(((100vw - 30px) / 2) * 16 / 9);
        }
      }
    }
    &--third {
      .single__gallery--layout:nth-child(1) {
        .caption {
          margin-bottom: 90px;

          @include mq(m) {
            margin-bottom: 0px;
          }
        }
      }

      .single__gallery--layout:nth-child(2) {
        .caption {
          margin-bottom: 90px;

          @include mq(m) {
            margin-bottom: 0px;
          }
        }
      }
      .single__gallery--image {
        width: 100%;
        height: calc(100vw * 4 / 3);
      }

      @include mq(m) {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 3fr 3fr 3fr;
        .single__gallery--image {
          width: 100%;
          height: calc(((100vw - 40px) / 3) * 16 / 9);
        }
      }
    }

    &--staggered {
      .single__gallery--layout:nth-child(1) {
        .caption {
          margin-bottom: 90px;
          @include mq(m) {
            margin-bottom: 0px;
          }
        }
      }
      // .single__gallery--layout {
      //   &:nth-child(2) {
      //     .caption {
      //       margin-bottom: 0;
      //     }
      //
      // }
      @include mq(m) {
        display: grid;
        grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
        grid-column-gap: 10px;

        .single__gallery--image {
          width: 100%;
          height: calc(100vw * 9 / 16);
        }
      }
    }
  }

  // .gallery__block--caption {
  //   .single__gallery--half,
  //   .single__gallery--third,
  //   .single__hald--staggered {
  //     // display: flex;
  //     // flex-direction: column-reverse;
  //     @include mq(m) {
  //       display: grid;
  //     }
  //     .caption {
  //       margin-bottom: 0;
  //     }
  //   }
  // }
  .caption {
    margin-top: 8px;
    // margin-bottom: 60px;
    @include mq(m) {
      margin-bottom: 0;
    }
  }
}
.single__artist {
  .title {
    font-style: normal;
  }
  .single__header--desc {
    transform: translate(0);
    margin-top: 14px;
  }
}
.single__exhibitions {
  padding-top: 70px;
  &--all {
    display: grid;
    grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
    grid-column-gap: 10px;
  }
  &--link {
    display: flex;
    align-items: flex-start;
    @include mq(s) {
      display: block;
    }
  }
  &--single {
    grid-column: span 12;
    @include mq(s) {
      grid-column: span 6;
    }
    @include mq(m) {
      grid-column: span 3;
    }
    &:nth-child(-n + 2) {
      @include mq(s) {
        margin-top: 24px;
      }
    }
    &:not(:first-child) {
      padding-top: 24px;

      @include mq(s) {
        margin-top: 0;
      }
      @include mq(m) {
        margin-top: 0;
      }
    }
    &:nth-child(2n + 2) {
      @include mq(m) {
        grid-column: 5 / 8;
      }
    }
    &:nth-child(3n + 3) {
      @include mq(m) {
        grid-column: 9 / 12;
      }
    }
  }
  &--figure {
    transform: translateY(3px);
    width: 36%;
    height: calc(((100vw - 20px) / 4));
    margin-right: 10px;
    @include mq(s) {
      transform: translateY(0px);

      margin-right: 0;
      width: 100%;
      height: calc(((100vw - 40px) / 3) * 15 / 16);
    }
    @include mq(m) {
      height: calc(((100vw - 40px) / 3) * 6 / 16);
    }
  }
  & {
    @include mq(s) {
      margin-top: 14px;
    }
  }
  &--text {
    // background: red;
    /* height: 100vh; */
    // color: rebeccapurple;
    height: calc(((100vw - 20px) / 4 + 5px));
    display: flex;
    flex-direction: column;
    .single__exhibitions--dates {
      margin-top: auto;
    }
    @include mq(s) {
      display: block;
      height: auto;
      margin-top: 19px;
      .single__exhibitions--dates {
        margin-top: 14px;
      }
    }
    @extend %typo--m;
    .title {
      @extend %typo--ms;
    }
  }

  h3 {
    @extend %typo--ml;
    margin-bottom: 14px;
  }
}
.single__information {
  margin-top: 0px;
  display: grid;
  .single__artisti--info {
    padding-top: 70px;
    @include mq(m) {
      padding-top: 5.3vw;
      margin-top: 2px;
    }
    &:nth-child(4) {
      padding-top: 0;
      margin-top: 14px;
      @include mq(m) {
        margin-top: 0;
        padding-top: 5.3vw;
        margin-top: 2px;
      }
    }
  }

  @include mq(m) {
    display: grid;
    grid-column-gap: 14px;
    grid-row-gap: 10px;
    grid-template-columns: 2fr 2fr;
  }

  &--text {
    margin-top: 10px;
    grid-row-gap: 14px;
  }
}
.single__bar--artist-mobile {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-column-gap: 10px;
  .single__bar--item {
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
.single__bar--artist {
  margin-top: 72px;
  display: grid;
  grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
  grid-column-gap: 10px;
  .bar--0 {
    grid-column: span 7;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 7fr 7fr 7fr 7fr 7fr 7fr 7fr;

    div {
      grid-column: span 1;
      text-align: center;
      &:nth-child(1) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(2) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(3) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(5) {
        text-align: center;
        grid-column: span 2;
      }
    }
    @include mq(m) {
      grid-column: span 5;
      grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
      div {
        grid-column: span 2;
        text-align: center;
        &:nth-child(1) {
          text-align: left;
          grid-column: span 1;
        }
        &:nth-child(3) {
          text-align: center;
          grid-column: span 2;
        }
      }
    }
  }
  .bar--1 {
    grid-column: span 5;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(3) {
        text-align: right;
        grid-column: span 1;
      }
    }
    @include mq(m) {
      grid-column: span 4;
      grid-template-columns: 2fr 2fr;

      div {
        grid-column: span 1;
        &:nth-child(1) {
          text-align: center;
          grid-column: span 1;
        }
        &:nth-child(2) {
          text-align: right;
          grid-column: span 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .bar--2 {
    grid-column: span 12;
    margin-top: 32px;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 3fr 3fr 3fr;
    div {
      grid-column: span 2;
      &:nth-child(1) {
        text-align: left;
        grid-column: span 1;
      }
      &:nth-child(2) {
        text-align: center;
        grid-column: span 1;
      }
      &:nth-child(3) {
        text-align: right;
        grid-column: span 1;
      }
    }

    @include mq(m) {
      grid-template-columns: 3fr 3fr 3fr;
      grid-column: span 3;
      margin-top: 0;
      .single__bar--5 {
        text-align: right;
        grid-column: span 1 !important;
      }
      div {
        grid-column: span 2;

        &:nth-child(2) {
          text-align: center;
          grid-column: span 2;
        }
        &:nth-child(3) {
          text-align: right;
          grid-column: span 1;
        }
      }
    }
  }
  a {
    text-align: center;
  }
  .single__bar--item {
    grid-column: span 2;
  }
  .single__bar--artist--item {
    text-align: center;
    grid-column: span 2;
  }
}
.single__bar--artist--1 {
  .bar--0 {
    grid-column: span 4;
  }
  .bar--1 {
    grid-column: span 4;
    display: flex;
    justify-content: center;
  }
  .bar--2 {
    grid-column: span 4;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 0;
  }
  .desktop {
    display: none;
    @include mq(m) {
      display: flex;
    }
  }
}

.single__bar--artist--2 {
  .bar--0 {
    grid-column: span 4;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 6;
      }
      &:nth-child(2) {
        text-align: left;
        grid-column: span 2;
      }
      &:nth-child(3) {
        align-self: start;
        text-align: center;
        grid-column: span 5;
      }
    }
    @include mq(m) {
      grid-column: span 4;

      div {
        &:nth-child(1) {
          text-align: center;
          grid-column: span 2;
        }
        &:nth-child(2) {
          text-align: center;
          grid-column: span 6;
        }
        &:nth-child(3) {
          text-align: center;
          grid-column: span 3;
        }
      }
    }
  }
  .bar--1 {
    grid-column: span 8;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 5fr 5fr 5fr 5fr 5fr;
    div {
      &:nth-child(1) {
        text-align: center;
        grid-column: span 4;
      }
      &:nth-child(2) {
        // background: blue;
        text-align: center;
        grid-column: span 2;
      }

      &:nth-child(3) {
        // background: cyan;
        text-align: right;
        grid-column: span 2;
        display: flex;
        justify-content: center;
      }
      &:nth-child(4) {
        // background: orange;
        text-align: right;
        grid-column: span 1;
        display: flex;
        justify-content: flex-end;
      }
    }
    @include mq(m) {
      grid-column: span 8;
      grid-template-columns: 8fr 8fr 8fr 8fr 8fr 8fr 8fr 8fr;

      div {
        grid-column: span 1;
        &:nth-child(1) {
          text-align: center;
          grid-column: span 4;
          display: flex;
          justify-content: center;
        }
        &:nth-child(2) {
          text-align: center;
          grid-column: span 1;
          display: flex;
          justify-content: center;
          display: none;
        }
        &:nth-child(3) {
          grid-column: span 2;
          // display: none;
        }

        &:nth-child(5) {
          text-align: right;
          // background: red;
          grid-column: span 2;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.desktop {
  display: none;
  @include mq(m) {
    display: grid;
  }
}

.mobile {
  @include mq(m) {
    display: none !important;
  }
}
.single__bar--item {
  a {
    @include mq($and: $hover) {
      &:hover {
        path {
          stroke: var(--red);
        }
      }
    }
  }
}

.uguale__desktop {
  margin-bottom: 0;
  @include mq(m) {
    display: none;
  }
}
.uguale__mobile {
  display: none;
  @include mq(m) {
    background: none;
    display: inline-block;
  }
}

.artworks__title {
  @extend %typo--ml;
  margin-bottom: 10px;
}

.single__artists {
  grid-column: 1 / -1;
  display: inline-block;

  * {
    display: inline;
    flex: 0 0 auto;
  }
}
</style>
