<template>
  <footer
    v-show="showFooter"
    class="page-footer"
    :class="$route.path === '/contacts/' ? 'page-footer__contacts' : null"
  >
    <div
      v-if="options && footer && footer.description"
      v-html="footer.description"
    />
    <div v-if="options && footer && footer.information">
      <div
        v-for="(info, indexInfo) in footer.information"
        :key="indexInfo"
        :class="info.section_class"
        class="footer__items"
      >
        <div
          v-for="(item, index) in info.section"
          :key="index"
          class="item"
        >
          <Flex>
            <Label
              v-if="item.label"
              :data="{ value: item.label }"
            />
            <Richtext
              v-if="item.content"
              :data="{ value: item.content }"
            />
            <Cta
              v-if="item.cta"
              :data="item.cta"
            />
          </Flex>
        </div>
        <div class="item">
          <Newsletter />
        </div>
        <div class="item">
          <router-link to="/cookie-policy/">
            Cookie Policy
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';
import Newsletter from '@/components/blocks/newsletter';

export default {
  name: 'Footer',
  components: {
    Label,
    Richtext,
    Cta,
    Newsletter,
  },
  computed: {
    ...mapGetters(['options', 'currentPost']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin-top: 116px;
  padding: 0 10px 2px;
  .wysiwyg {
    @extend %typo--xs;
  }
}
.page-footer__contacts {
  /* height: 0;
  padding: 0;
  margin: 0;
  .item:nth-child(1),
  .item:nth-child(2),
  .item:nth-child(4),
  .item:nth-child(5) {
    display: none;
  } */
  // @include mq(m) {
    height: auto;
    padding: 0 10px 9px;
    margin: 0;

    .item:nth-child(1),
    .item:nth-child(2),
    .item:nth-child(4),
    .item:nth-child(5) {
      display: flex;
    }
  // }

  @include mq($until: m) {
    .item:nth-child(4) {
      display: none;
    }
    .item:nth-child(5) {
      align-self: center;
    }
  }
}
.footer__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;

  div:nth-child(1),
  div:nth-child(2) {
    width: 100%;
    text-align: center;
  }
  div:nth-child(1) {
    order: 2;
  }
  div:nth-child(2) {
    margin-top: 14px;
    order: 4;
  }
  div:nth-child(3) {
    margin-top: 14px;
    order: 3;
    // background: red;
    // margin: 0 20px;
    // transform: translateY(-4px);
  }
  div:nth-child(4) {
    order: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
  }
  div:nth-child(5) {
    order: 5;
    text-align: center;
    margin-top: 14px;
    @extend %typo--xs;
  }
  @include mq(m) {
    margin-bottom: 0px;
    display: grid;
    grid-template-columns: 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr 12fr;
    grid-column-gap: 10px;
    div:nth-child(1) {
      width: fit-content;
      text-align: left;
      grid-column: span 3;
      order: 1;
    }
    div:nth-child(2) {
      text-align: left;
      grid-column: span 2;
      margin-top: 0;
            order: 2;

    }
    div:nth-child(3) {
      grid-column: span 2;
      display: flex;
      justify-content: center;
            order: 3;
      transform: translateY(-8px);
    }
    div:nth-child(4) {
      text-align: left;
      margin: 0;
      padding: 0;
      grid-column: 9 / 12;
            order: 4;

    }
    div:nth-child(5) {
      text-align: right;
      grid-column: 12 / 13;
      margin-top: 0;
            order: 5;

    }
  }
}
</style>
