<template>
  <Spacer
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :class="[
      layout.section_classes && layout.section_classes !== ''
        ? layout.section_classes.split(' ')
        : null,
      `layout--${layout.section_layout}`,
    ]"
    :style="{
      backgroundColor:
        layout.section_background !== '' ? layout.section_background : null,
      color: layout.section_text !== '' ? layout.section_text : null,
    }"
    tag="section"
    :template="sectionSpacer"
  >
    <Wrapper
      :size="
        layout.section_wrapper && layout.section_wrapper !== 'no'
          ? layout.section_wrapper
          : 'l'
      "
    >
      <slot />
    </Wrapper>
  </Spacer>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sectionSpacer() {
      let template = 'section';

      if (this.layout.section_spacer === 's') template = 'section-small';
      else if (this.layout.section_spacer === 'm') template = 'section-medium';
      else if (this.layout.section_spacer === 'l') template = 'section-large';
      else if (this.layout.section_spacer === '0') template = 'section-no-space';

      return template;
    },
  },
};
</script>
