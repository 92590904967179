<template>
  <component
    :is="data.id"
    :data="data"
  />
</template>

<script>
import Simple from '@/components/blocks/custom/simple';
import Exhibitions from '@/components/blocks/custom/exhibitions';
import Fairs from '@/components/blocks/custom/fairs';

export default {
  name: 'Custom',
  components: {
    Simple,
    Exhibitions,
    Fairs,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
