<template>
  <component
    :is="to ? 'router-link' : 'div'"
    :class="[
      'icon',
      `icon-theme--${theme}`,
      `icon--size-${size}`,
      `icon-${name}`,
    ]"
    :to="to"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'l',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.icon {
  position: relative;
  cursor: pointer;

  svg {
    width: $unit * 2;
    height: $unit * 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--size-xs {
    width: $unit * 1.5;
    height: $unit * 1.5;
    svg {
      width: $unit * 1.5;
    }
  }

  &--size-s {
    width: $unit * 2;
    height: $unit * 2;
  }

  &--size-m {
    width: $unit * 2.5;
    height: $unit * 2.5;
  }

  &--size-l {
    width: $unit * 4;
    height: $unit * 4;
  }

  &-theme {
    &--default {
      // background: var(--primary-color);
      color: var(--black);
    }

    &--warning {
      path {
        fill: var(--warning-color);
      }
    }
    &--invert {
      path {
        fill: var(--white);
      }
    }

    &--circle {
    background: var(--black);
    border-radius: 50%;
    path {
      fill: var(--white);
    }
  }
  }

  &--close {
    position: absolute;
    top: $unit * 0.5;
    right: $unit * 0.5;
    z-index: 10;
  }
}
</style>
