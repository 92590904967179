<template>
  <Grid :col="cols">
    <component
      :is="thumb()"
      v-for="item in items"
      :key="item"
      :class="['skeleton', `skeleton--${thumbComponent}`]"
    />
  </Grid>
</template>

<script>
import Thumb from '@/components/thumb/';
import ThumbProduct from '@/components/thumb/product';

const thumbs = new Map();
thumbs.set('default', Thumb);
thumbs.set('product', ThumbProduct);

export default {
  name: 'Skeleton',
  props: {
    thumbComponent: {
      type: String,
      default: 'default',
    },
    items: {
      type: Number,
      required: true,
    },
    cols: {
      type: Object,
      default: () => ({
        default: 12,
        m: 3,
      }),
    },
  },
  methods: {
    thumb() {
      return thumbs.get(this.thumbComponent);
    },
  },
};
</script>

<style lang="scss">
.skeleton {
  pointer-events: none;
  cursor: default;

  * {
    cursor: default !important;
    pointer-events: none !important;
  }

  &--default {
  }
}
</style>
