<template>
  <div
    v-if="!success"
    :class="['form', avoid ? 'form-avoid' : false]"
    class="newsletter"
  >
    <form @submit.prevent="submit">
      <input
        v-model="email"
        type="email"
        name="email"
        required
        placeholder="Your e-mail here"
      >
      <div>
        <button>Subscribe</button>
      </div>
    </form>
  </div>
  <div v-else>
    Thank you!
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Newsletter',
  components: {},

  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  computed: {
    ...mapGetters(['options']),
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.options.extra.mailchimp_list_id.value,
          email: this.email,
        },
      };
      this.$store
        .dispatch('sendRequest', request)
        .then((response) => {
          if (response.data.status && response.data.status === 'subscribed') {
            this.success = true;
          } else {
            this.success = false;
            this.avoid = false;
            console.log(response.data.title);
          }
        })
        .catch((error) => {
          this.success = false;
          this.avoid = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
      background: transparent;

  -webkit-appearance: none;
  border: none;
  font-family: $sans;
  @extend %typo--mail;
  text-align: center;
  width: 100%;

  @include mq(m) {
    text-align: left;
  }
}
::placeholder {
  opacity: 0.6;
}
button {
  @extend %typo--mail;
      margin-top: 8px;

  @include mq(m){
    margin-top: 0px;
  }
}
form{
    width: 100%;

}
.form {
  width: 100%;
      background: none;

  &-avoid {
    cursor: progress;
    background: none;

    form {
          background: none;

      pointer-events: none;
    }
  }
}
.newsletter{
  width: 100%!important;
        background: none;

}
</style>
